.article-card {
	background:#fff;
	display:inline-block;
	color:inherit;
	text-decoration:none;
	width:100%;
	position:relative;
	transition:transform .6s cubic-bezier(.51,.92,.24,1.15);
	backface-visibility: hidden;
	box-shadow:none;

	&:after {
		content:'';
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
		transition: opacity 0.3s ease-in-out;
		opacity:0;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
	}


	&:hover {
		&:after {
			opacity:1;
		}
		.article-card__continue span {
			color:$red;
		}
	}

	&--white-title {
		.article-card__category {
			color:#fff;
		}
		.article-card__title {
			color:#fff;
		}
		.article-card__content {
			color:#fff;
		}
		.article-card__continue span {
			color:#fff;
		}

		&:hover {
			.article-card__continue span {
				color:#fff;
				text-decoration:underline;
			}
		}
	}

	&__thumbnail {
		width:100%;
		height:auto;
	}

	&.has-image {
		.article-card__inner {
			position:static;
			transform:none;
		}
	}

	&__inner {
		padding:60px;
		@include vertical-align(absolute);

		@include below($m){
			padding:30px;
			position:static;
			transform:none;
		}

		&--fullwidth {
			position:static;
			transform:none;
			max-width:50%;

			@include below($s){
				max-width:100%;
			}
		}
	}

	&__category {
		font-size:rem(18px);
		line-height:rem(18px);
		color:rgba(0, 0, 0, .5);
		margin-bottom:15px;
		display:block;
		font-family:$groteskregular;
	}

	&__title {
		font-size:rem(40px);
		line-height:rem(44px);
		margin-bottom:16px;
		transition:color .2s ease-in-out;

		@include below($s){
			font-size:4rem;
			line-height:4.2rem;
		}
	}

	&__content {
		color:rgba(0, 0, 0, .5);
		font-size:rem(20px);
		line-height:rem(28px);
		margin-bottom:50px;
		font-family: $groteskregular;

		@include below($s){
			margin-bottom:20px;
		}
	}

	&__continue {
		display:flex;
		flex-direction: row;
		align-content: center;
		height:50px;

		@include below($s){
			height:30px;
		}

		span {
			align-self:center;
			margin-top:4px;
			margin-left:10px;
			font-size:rem(15px);
			line-height:rem(15px);
			font-family:$groteskregular;
			transition:color .2s ease-in-out;
		}

		img {
			@include below($s){
				max-width:30px;
				height:30px;
			}
		}
	}
}
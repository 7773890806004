.big-speak {
	height:900px;
	height: 700px;
	display:flex;
	flex-wrap:wrap;
	background-size:1180px 900px;
	background-size: auto 700px;
	background-position:center center;
	background-repeat:no-repeat;

	@include below($s){
		height:calc(100vh - 59px);
		padding: 100px 0;
		background-size: 500px;
		background-position: bottom right;
	}

	&__wrap {
		max-width:1180px;
		width:100%;
		margin:0 auto;
		align-self:center;
		display:flex;
		flex-direction: row;

		&--right {
			justify-content:flex-end;
		}
	}

	&__holder {
		width:100%;
		max-width:530px;
		height: 400px;
		top: 50px;
		position: relative;

		@include below(1280px){
			padding:0 20px;
		}

		@include below($s) {
			height: auto;
			top: 0;
		}
	}

	&__category {
		font-size:rem(18px);
		line-height:rem(18px);
		color:#fff;
		margin-bottom:20px;
		display:block;
		font-family:$groteskregular;
	}

	&__title {
		font-size:rem(72px);
		line-height:rem(64px);
		letter-spacing:-0.72px;
		color:#fff;
		margin-bottom:15px;
		strong {
			font-family:$groteskmedium;
			font-weight: 100;
			line-height: 0;
		}

		@include below($s) {
			font-size:5rem;
			line-height:4.5rem;
		}
	}

	p {
		font-size:rem(20px);
		line-height:rem(28px);
		color:#fff;
		font-family:$groteskregular;
	}
}
.post-navigation {
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;

	&__col {
		width:50%;
		height:450px;
		text-decoration:none;
		text-align:center;
		position:relative;
		padding:40px;

		@include below($s){
			padding:20px;
			height: 150px;
		}

		span {
			color:#fff;
			font-size:rem(50px);
			line-height:rem(50px);
			display:block;
			margin-bottom:30px;
			max-width: 640px;
			display: inline-block;

			@include below ($s) {
				max-height: 32px;
				width: 75%;
				display: block;
				margin: 0 auto;
				margin-bottom: 10px;
				white-space: nowrap;
				text-overflow: ellipsis;
				display: block;
				overflow: hidden;
				font-size: 2rem; 
				line-height: 3rem;
			}
		}
	}

	&.single-post {
		.post-navigation__col {
			width: 100%;
		}
	}

	&__inner {
		@include vertical-align(absolute);
		left:40px;
		right:40px;

		@include below ($s) {
			left: 0;
			right: 0;
		}
	}

	&__button {
		display:flex;
		text-align:center;
		color:inherit;
		width:102px;
		margin:0 auto;
		color:#fff;

		@include below ($s) {
			flex-direction: column;
			align-items: center;
		}

		&:hover {
			text-decoration:underline;
		}

		span {
			color:#fff;
			font-size:rem(12px);
			line-height:rem(12px);
			font-family:$groteskregular;
			letter-spacing:0.4px;
			align-self:center;
			justify-self: center;
			margin-top:4px;
			margin-bottom:0;

			@include below ($s) {
				width: 100%; 
			}
		}

		img {
			width:40px;
			height:40px;
			margin-right:18px;

			@include below ($s) {
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
	}
}
.development-header {
	background-color:#7D559B;
	height:690px;
	height: 700px;
	display:flex;
	background-image:url('../img/what-we-do_3_small_r_2.png');
	background-size:1460px auto;
	background-position:center center;
	background-repeat:no-repeat;

	@include below($s){
		height:calc(100vh - 59px);
		padding: 100px 0;
		background-size: 500px;
		background-position: bottom right;
	}

	&__inner {
		max-width:1180px;
		width:100%;
		margin:0 auto;
		align-self:center;
		height: 400px;
		position: relative;
		top: 50px;

		@include below(1280px){
			padding:0 20px;
			max-width:100%;
		}

		@include below($s) {
			height: auto;
			top: 0;
		}
	}

	&__title {
		color:#fff;
		font-size:rem(72px);
		line-height:rem(64px);
		font-family:$genathregular;
		margin-bottom: 15px;

		strong {
			font-family:$groteskmedium;
			font-weight: 100;
			line-height: 0;
		}

		@include below($s){
			font-size:4rem;
			line-height:4rem;
		}
	}

	p {
		font-size:rem(20px);
		line-height:rem(28px);
		font-family:$groteskregular;
		color:#fff;
	}
}
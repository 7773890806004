.speak-card {
	background-color:#F03E2F;
	display:inline-block;
	color:inherit;
	text-decoration:none;
	width:100%;
	position:relative;
	padding:60px;

	@include below($s){
		padding:20px;
	}

	&__category {
		font-size:rem(18px);
		line-height:rem(18px);
		color:#fff;
		margin-bottom:15px;
		display:block;
		font-family:$groteskregular;
	}

	&__title {
		color:#fff;
		font-size:rem(40px);
		line-height:rem(44px);
		margin-bottom:16px;
		letter-spacing:-0.56px;

		strong {
			font-family:$groteskregular;
		}


		@include below($s){
			font-size:3rem;
			line-height:3.3rem;
		}
	}

	&__content {
		color:#fff;
		font-size:rem(20px);
		line-height:rem(28px);
	}
}
.thoughtpiece-card {
	text-align:center;
	padding:70px;

	@include below($s){
		padding:20px;
	}

	&--frontpage {
		.thoughtpiece-card__title {
			font-size: 4rem;
			line-height: 4.4rem;
		}
	}

	&__subtitle {
		color:#fff;
		font-size:rem(18px);
		line-height:rem(32px);
		font-family:$groteskregular;
		margin-bottom:100px;
		display:block;

		@include below($s){
			margin-bottom:30px;
		}
	}

	&__title {
		font-size:rem(72px);
		line-height:rem(64px);
		color:#fff;
		font-family:$genathregular;
		margin-bottom:22px;

		strong {
			font-family:$groteskmedium;
			font-weight: 100;
		}

		@include below($s){
			font-size:4rem;
			line-height:4rem;
		}
	}

	p {
		font-size:rem(20px);
		line-height:rem(28px);
		color:#fff;
		font-family:$genathregular;
		max-width:650px;
		width:100%;
		margin:0 auto;
		margin-bottom:85px;
		font-family: $groteskregular;

		@include below($s){
			margin-bottom:30px;
		}
	}

	a {
		color:#fff;

		&:hover {
			text-decoration: none;
		}
	}

	&__author {
		h3 {
			color:#fff;
			font-size:rem(36px);
			line-height:rem(32px);
			font-family:$genathitalic;
			margin-bottom:10px;
			strong {
				font-family:$groteskmedium;
				font-weight: 100;
			}
		}
		span {
			color:#fff;
			font-size:rem(12px);
			line-height:rem(12px);
			font-family:$groteskregular;
			letter-spacing:1.33px;
			text-transform:uppercase;
		}
	}
}
.page-template-default{
	.article {	
		&__wrapper {
			margin-top: 120px;
			font-size: 20px;

			@include below($s){
				margin-top: 10px;
			}
		}
	}	
} 
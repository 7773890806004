.button {
	border:1px solid rgba(46,46,46, .2);
	padding: 22px 36px 16px 36px;
	font-size:rem(18px);
	line-height:rem(18px);
	text-decoration:none;
	display:inline-block;
	border-radius:59px;
	color:#2E2E2E;
	font-family:$groteskregular;
	transition:color .2s ease-in-out;

	@include below(1024px){
		padding: rem(22px) rem(36px) rem(16px) rem(36px);
	}

	&--white {
		background-color:#fff;


		&:hover {
			color:$red;
		}
	}
}
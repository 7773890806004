.list {
	// max-width:680px;
	// max-width: 710px;
	width:100%;
	margin:0 auto;

	p,
	h1,
	h2,
	h3 {
		padding: 0 30px;

		@include below($m) {
			padding: 0;
		}
	}

	&__wrapper {
		max-width:1180px;
		width:100%;
		margin:0 auto;
		background-color:#fff;
		padding:60px;

		@include below($s){
			padding:20px;
		}
	}

	&__category {
		font-size:rem(18px);
		line-height:rem(32px);
		color: rgba(0, 0, 0, 0.50);
		font-family:$groteskregular;
		display:block;
		margin-bottom:28px;
		padding: 0 30px;

		@include below($m) {
			padding: 0;
		}
	}

	&__title {
		font-size:rem(50px);
		line-height:rem(50px);
		letter-spacing:-0.5px;
		font-family:$genathregular;
		color:#2E2E2E;
		margin-bottom:rem(37px);
		padding: 0 30px;

		@include below($m) {
			padding: 0;
		}
	}

	&__content {
		margin-bottom:50px;

		a {
			color:#2e2e2e;
			&:hover {
				text-decoration:none;
			}
		}

		p {
			font-family:$genathregular;
			font-family: $groteskregular;
			font-size:rem(20px);
			line-height:rem(32px);
			margin-bottom:rem(30px);
		}

		h2 {
			font-size:rem(32px);
			line-height:rem(38px);
			margin-bottom:rem(10px);
		}

		strong {
			font-family:$groteskmedium;
			color:#000000;
			font-weight:500;
		}

		ul {
			list-style:none;
			margin-bottom:rem(30px);

			li {
				font-family:$genathregular;
				font-size:rem(20px);
				line-height:rem(32px);
				margin-bottom:rem(10px);
				padding-left:20px;
				position:relative;

				&:before {
					content:'';
					width:6px;
					height:6px;
					border-radius:100%;
					background-color:#2E2E2E;
					display:block;
					position:absolute;
					top:12px;
					left:6px;
				}
			}
		}

		ol {
			margin-bottom:rem(30px);
			li {
				font-family:$genathregular;
				font-size:rem(20px);
				line-height:rem(32px);
				margin-bottom:rem(10px);
			}
		}



		img {
			vertical-align: top;
			margin-bottom:rem(30px);

			&.aligncenter {
				width:100%;
				height:auto;
			}

			&.alignnone {
				width:100%;
				height:auto;
			}

			@include below($m){
				width:100%;
				height:auto;
			}
		}

		.alignleft {
			float:left;
			margin:9px 30px 0px 0;

			@include below($m){
				width:100%;
				height:auto;
				margin-bottom:15px;
			}
	  	}

		.alignright {
			float:right;
			margin:9px 0 0 30px;

			@include below($m){
				width:100%;
				height:auto;
				margin-bottom:15px;
			}
		}

		img.alignright {
			float:right;
			margin:9px 0 30px 30px;

			@include below($m){
				width:100%;
				height:auto;
			}
		}
		img.alignleft {
			float:left;
			margin:9px 30px 30px 0;

			@include below($m){
				width:100%;
				height:auto;
			}
		}
		img.aligncenter {
			display: block;
			margin-left: auto;
			margin-right: auto;

			@include below($m){
				width:100%;
				height:auto;
			}
		}
		a img.alignright {
			float:right;
			margin:9px 0 30px 30px;

			@include below($m){
				width:100%;
				height:auto;
			}
		}
		a img.alignleft {
			float:left;
			margin:9px 30px 30px 0;

			@include below($m){
				width:100%;
				height:auto;
			}
		}
		a img.aligncenter {
			display: block;
			margin-left: auto;
			margin-right: auto;

			@include below($m){
				width:100%;
				height:auto;
			}
		}

		.wp-caption {
			@include below($m){
				width:100% !important;
				height:auto !important;
			}
			img {
				margin-bottom:18px;

				@include below($m){
					width:100% !important;
					height:auto !important;
				}
			}
		}

		.wp-caption-text {
			font-size:rem(16px);
			line-height:rem(20px);
			color:rgba(0, 0, 0, .31);
			font-family:$groteskregular;
		}
	}

	&__posted-in {
		display:flex;
		flex-direction: row;
		margin-bottom:50px;

		p {
			font-size:rem(16px);
			font-family:$groteskregular;
			margin-right:5px;
		}

		a {
			color:$red;
			font-size:rem(16px);
			font-family:$groteskregular;
			text-decoration:none;
			transition:opacity .2s ease-in-out;
		}

		span {
			margin-left:5px;
			margin-right:5px;
			font-size:rem(16px);
			font-family:$groteskregular;
			color:$red;
		}
	}

	&__author {
		padding: 0 30px;

		@include below($m) {
			padding: 0;
		}

		h3 {
			color:#000;
			font-size:rem(36px);
			line-height:rem(32px);
			font-family:$genathitalic;
			margin-bottom:10px;
			padding: 0;

			strong {
				font-family:$groteskmedium;
				font-weight: 100;
			}
		}
		span {
			color:#000;
			font-size:rem(12px);
			line-height:rem(12px);
			font-family:$groteskregular;
			letter-spacing:1.33px;
			text-transform:uppercase;
		}
	}
	&__title {
		font-size:rem(40px);
		line-height:rem(44px);
		transition:color .2s ease-in-out;	
		width: 70%;
		margin-bottom: 0;
		display: flex;
		align-items: center;

		@include below($s){
			font-size:4rem;
			line-height:4.2rem;
		}
	}
	&__header{
		display: flex;
		margin-bottom: 16px;
	}
	
	&__item {
		color:rgba(0, 0, 0, .5);
		font-size:rem(20px);
		line-height:rem(28px);
		margin-bottom:50px;
		font-family: $groteskregular;
		display: flex;

		@include below($s){
			margin-bottom:20px;
		}
		&__content{
			width: 70%;
			@include below($m){
				width: 100%;
			}
		}

		&__image{
			@include below($m) {
				display: none;
			}
			width: 30%;
			img{
				width: 80%;
				padding: 0 1em;
			}
			&-mobile{
				display: none;

				img{
					max-width: 100%;
					height: auto;
					width: auto;
				}
				@include below($m) {
					display: block;
					width: 30%;
					display: flex;
					flex-direction: row-reverse;
				}
			}
		}
	}
}
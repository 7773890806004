.team {
	width:100%;
	max-width:1200px;
	margin:0 auto;
	padding:0 0 130px;

	&.job-listing-intro {
		padding: 0;
	}

	@include below($s){
		padding:20px 0;
	}

	&__intro {
		width:50%;
		margin:130px auto 0;
		text-align:center;
		margin-bottom:80px;

		@include below($s){
			margin-bottom:40px;
		}

		span {
			font-size:rem(18px);
			line-height:rem(32px);
			font-family:$groteskregular;
			color:rgba(46,46,46, .5);
			margin-bottom:33px;
			display:block;
		}

		h2 {
			font-size:rem(72px);
			line-height:rem(64px);
			letter-spacing:-0.72px;
			font-family:$genathregular;
			margin-bottom:47px;

			strong {
				font-family:$groteskmedium;
				font-weight: 100;
			}

			@include below($s){
				margin-bottom:15px;
				font-size:5rem;
				line-height:4.5rem;
			}
		}

		p {
			font-size:rem(20px);
			line-height:rem(28px);
			color:rgba(46,46,46, .8);
			//font-family:$genathregular;
			font-family:$groteskregular;
		}

		@include below($s){
			width:100%;
			padding:0 20px;
		}
	}

	&__grid {
		clear:both;
		overflow:hidden;
	}

	&__column {
		width:33.333%;
		float:left;
		padding:0 10px;
		&:nth-child(2){
			margin-top:115px;

			@include below($s){
				margin-top:0px;
			}
		}

		@include below($s){
			width:100%;
		}
	}

	&__member {
		margin-bottom:20px;
		position:relative;
		overflow:hidden;


		&:after {
			content:'';
			position:absolute;
			bottom:0;
			left:0;
			right:0;
			height:260px;
			background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.60) 100%);
		}

		&__image {
			width:100%;
			height:auto;
			vertical-align: top;
			transition:transform .4s ease-in-out;
		}

		&__details {
			position:absolute;
			bottom:0px;
			left:0px;
			right:0px;
			padding:20px;
			z-index:2;

			h2 {
				color:#fff;
				font-size:rem(18px);
				line-height:rem(24px);
				font-family:$groteskmedium;
			}

			span {
				color:#fff;
				font-size:rem(18px);
				line-height:rem(18px);
				margin-bottom:10px;
				font-family:$groteskregular;
				display:block;
			}

			a {
				color:#fff;
				font-size:rem(16px);
				line-height:rem(18px);
				font-family:$groteskregular;
				display:block;
				margin-left:auto;
				margin-right:auto;
				margin-bottom:5px;
				text-decoration:none;

				&:last-child {
					margin-bottom:0;
				}

				&:hover {
					text-decoration:underline;
				}
			}
		}

		&__continue {
			display:flex;
			flex-direction: row;
			align-content: center;
			max-height:0px;
			overflow:hidden;
			opacity:0;
			transition:max-height .2s ease-in-out, opacity .4s ease-in-out;

			img {
				width:25px;
				height:25px;
			}

			span {
				align-self:center;
				margin-top:2px;
				margin-left:10px;
				font-size:rem(12px);
				line-height:rem(12px);
				font-family:$groteskregular;
				transition:color .2s ease-in-out;
				margin-bottom:0;
			}
		}

		&:hover {
			cursor:pointer;
			.team__member__continue {
				max-height:25px;
				opacity:1;
			}
			.team__member__image {
				transform:scale(1.1);
			}
		}
	}

	&__image {
		max-width:1175px;
		width:100%;
		margin:0 auto;
		display: block;
	}

	&__memberinfo {
		position:fixed;
		max-width:1180px;
		width:100%;
		left:50%;
		margin-top:-309.5px;
		margin-left:-590px;
		z-index:99;
		opacity:0;
		transition:top .2s ease-in-out, opacity .4s ease-in-out;
		display:flex;
		flex-direction: row;
		top:100%;
		pointer-events: none;

		@include above($s){
			height:618px;
		}

		@include below(1180px){
			left:0;
			right:0;
			margin:0;
			flex-direction:column;

			overflow-y: scroll;
			height: 100vh;
		}

		&__image {
			width:40%;
			img {
				width:100%;
				height:auto;
				vertical-align: top;
			}

			@include below($s){
				width:100%;
			}
		}

		&__content {
			width:60%;
			background-color:#fff;
			padding:50px;

			@include above($s){
				overflow: hidden;
				overflow-y: scroll;
			}


			@include below($s){
				width:100%;
				padding:20px;
			}

			.intro {
				margin-bottom:30px;
			}

			.phone {
				color:#2E2E2E;
				font-size: 1.6rem;
				line-height: 1.8rem;
				font-family: "Conv_Founders Grotesk - Regular";
				display: block;
				margin-left: auto;
				margin-right: auto;
				text-decoration: none;
				margin-bottom:10px;
				&:hover {
					text-decoration:underline;
				}
			}

			.email {
				color:#2E2E2E;
				font-size: 1.6rem;
				line-height: 1.8rem;
				font-family: "Conv_Founders Grotesk - Regular";
				display: block;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 5px;
				text-decoration: none;
				&:hover {
					text-decoration:underline;
				}
			}

			.name {
				font-size:4rem;
				line-height:4.4rem;
				margin-bottom:10px;
			}

			.position {
				font-size:rem(20px);
				line-height:rem(20px);
				display:block;
				font-family:$groteskmedium;
				margin-bottom:10px;
			}

			.content {
				font-size:rem(20px);
				line-height:rem(28px);
				margin-bottom:20px;
			}

			.title {
				font-family:$groteskmedium;
				font-size:rem(20px);
				line-height:rem(28px);
			}

			.linkedin {
				width:40px;
				height:40px;
				display:block;
				background-image:url('../img/icon-linkedin-black.svg');
				background-size:40px 40px;
				transition:opacity .2s ease-in-out;

				&:hover {
					opacity:0.5;
				}
			}
		}

		&.visible {
			top:50%;
			opacity:1;
			pointer-events: auto;

			@include below($s){
				top:0px;
			}
		}

		&__close {
			position:absolute;
			top:20px;
			right:20px;
			width:32px;
			height:32px;

			span {
				width:100%;
				height:2px;
				background-color:#2E2E2E;
				display:block;
				position:absolute;



				&:nth-child(1){
					transform:rotate(45deg);
					top:15px;
				}

				&:nth-child(2){
					transform:rotate(-45deg);
					top:15px;
				}
			}
		}
	}
}
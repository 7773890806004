.news-card {
	padding:60px;
	border:1px solid #E3E3E3;
	display:inline-block;
	color:inherit;
	text-decoration:none;
	width:100%;
	background-color:#f7f7f7;
	position:relative;
	transition:box-shadow .2s ease-in-out;

	&:after {
		content:'';
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
		transition: opacity 0.3s ease-in-out;
		opacity:0;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
	}

	&:hover {
		&:after {
			opacity:1;
		}
	}

	&--white-title {
		.news-card__category {
			color:#fff;
		}

		.news-card__title {
			color:#fff;
		}

		.news-card__content {
			color:#fff;
		}
		.news-card__continue span {
			color:#fff;
		}
		&:hover {
			.news-card__continue span {
				color:#fff;
				text-decoration:underline;
			}
		}
	}

	@include below($s){
		padding:30px;
	}

	&--image {
		padding:0;

		img {
			width:100%;
			height:auto;
		}

		.news-card__inner {
			padding:60px !important;
		}
	}

	&__category {
		font-size:rem(18px);
		line-height:rem(18px);
		color:rgba(0, 0, 0, .5);
		margin-bottom:15px;
		display:block;
		font-family:$genathitalic;
	}

	&__title {
		font-size:rem(40px);
		line-height:rem(44px);
		letter-spacing:-0.4px;
		margin-bottom:16px;
		transition:color .2s ease-in-out;

		@include below($s){
			font-size:4rem;
			line-height:4.2rem;
		}
	}

	&__content {
		font-size:rem(20px);
		line-height:rem(28px);
		color:rgba(46, 46, 46, .8);
		margin-bottom:50px;
		font-family: $groteskregular;
	}
	&__continue {
		display:flex;
		flex-direction: row;
		align-content: center;
		height:50px;

		@include below($s){
			height:30px;
		}

		span {
			align-self:center;
			margin-top:4px;
			margin-left:10px;
			font-size:rem(15px);
			line-height:rem(15px);
			font-family:$groteskregular;
			transition:color .2s ease-in-out;
		}

		img {
			width: 50px;
			@include below($s){
				max-width:30px;
				height:30px;
			}
		}
	}
}

.article-listing__grid-item {
	&.news-card {
		.news-card__inner {
			padding:60px;
			@include vertical-align(absolute);

			@include below($m){
				padding:20px;
				transform:none;
				position:static;
				padding:30px !important;
			}
		}
	}

	&.has-image {
		.news-card__inner {
			padding:60px;
			position:static;
			transform:none;

			@include below($m){
				padding:30px;
			}
		}
	}
}

.news-article-image {
	width: 100%; 
	padding: 0 30px 30px;

	@include below($s){
		padding: 0 0 30px 0;
	}
}
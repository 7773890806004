.chat-bot {
	position: fixed;
	bottom: 30px;
	right: 30px;
	width: 100px;
	height: 100px;
	background: #fff;
	border: 5px solid #fff;
	border-radius: 100px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	text-decoration: none;
	transition: bottom .2s ease, opacity .6s ease-in-out;
	margin: 0;
	opacity:0;
	z-index:10;

	&.visible {
		opacity:1;
	}

	&.contact-block-visible {
		opacity:0;
	}

	@include below($s) {
		//display: none;
		width: 80px;
		height: 80px;
	}

	.talk-to-me {
		padding: 10px;
		background: $red;
		color: #fff;
		@include vertical-align(absolute);
		font-family: $groteskregular;
		padding: 22px 36px 16px 36px;
    	font-size: 1.8rem;
    	line-height: 1.8rem;
    	height: 56px;
    	display: inline-block;
    	white-space: nowrap;
    	border-radius: 56px;
    	right: 100px;
    	right: 80px;
    	opacity: 0;
    	transition: all .3s ease;
	}

	&:hover {
		bottom: 35px;

		.talk-to-me {
			right: 95px;
			opacity: 1;
			display: none;
		}
	}
}

.chat-window {
	position: fixed;
	bottom: 150px;
	right: 30px;
	background: #fff;
	border-radius: 10px;
	background: #fff;
	//border: 5px solid #fff;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
	overflow: hidden;
	width: 320px;
	//display: none;
	transition: opacity .1s ease, bottom .2s ease;
	opacity: 0;
	pointer-events: none;
	bottom: 130px;
	z-index: 1001;

	@include below($s) {
		width: 100%;
		//height: 100%;
		overflow: auto;
		bottom: 0;
		right: 0;
		z-index: 1001;
	}

	&.visible {
		opacity: 1;
		pointer-events: auto;
		bottom: 150px;

		@include below($s) {
			bottom: 30px;
		}
	}

	.chat-window-top {
		background: #2E4591;
		padding: 30px;
		color: #fff;
		text-align: center;

		.chat-bot-image {
			display: inline-block;
			border-radius: 100px;
			border: 5px solid #fff;
			margin-bottom: 30px;
			width: 80px;
			height: 80px;
			display: none;
		}
	}

	.chat-window-title {
		font-family: $groteskmedium;
		font-weight: 100;
		text-align: center;
		font-size: 20px;

		.cb-name {
			font-family: $genathitalic;
			display: block;
			font-size: 24px;
		}
	}

	.chat-input-holder {
		display: block;
		//margin: 10px 0;
		padding: 0 20px;
		margin-top: 20px;

		textarea {
			width: 100%;
			height: 150px;
			max-width: 100%;
			min-width: 100%;
			max-height: 150px;
			resize: none;
			font-size: 18px;
			line-height: 22px;
			padding: 10px;
			-webkit-appearance: none;
			box-shadow: none;
			outline: 0;
			border: 0;
			border: 1px solid #dadada;
			font-family: $groteskregular;
			background: #f7f7f7;
		}

		input[type=email] {
			font-size: 18px;
			line-height: 22px;
			padding: 15px 10px 12px;
			display: block;
			width: 100%;
			-webkit-appearance: none;
			box-shadow: none;
			outline: 0;
			border: 0;
			border: 1px solid #dadada;
			font-family: $groteskregular;
			background: #f7f7f7;
		}

		input[type=submit] {
			-webkit-appearance: none;
			background: #2E4591;
			width: 100%;
			max-width: 100px;
			text-align: center;
			max-width: 200px;
			color: #fff;
			border: 0;
		}
	}

	.chat-btn-holder {
		text-align: center;
		padding-bottom: 30px;
		margin-top: 30px;
	}
}

.thank-you-msg {
	position: absolute;
	width: 100%;
	height: 100%;
	background: #2E4591;
	top: 0;
	left: 0;
	display: none;

	p {
		color: #fff;
		font-size: 32px;
		@include vertical-align(absolute);
		width: 100%;
		text-align: center;
	}
}

.close-cross {
	position:absolute;
	top:10px;
	right:10px;
	width:32px;
	height:32px;
	cursor: pointer;

	span {
		width:100%;
		height:2px;
		background-color: #fff;
		display:block;
		position:absolute;



		&:nth-child(1){
			transform:rotate(45deg);
			top:15px;
		}

		&:nth-child(2){
			transform:rotate(-45deg);
			top:15px;
		}
	}
}
.video-hero {
	position: relative;
	padding-top: 56.25%; /*16:9*/
	height: 0;
	overflow: hidden;

	@include above(1440px){
		padding-top:0;
		height:90vh;
		width:100%;
	}

	@include below($m){
		height:100vh;
		margin-bottom:20px;
		padding-top:0;
	}

	@include below(375px){
		height:667px;
		margin-bottom:20px;
		padding-top:0;
	}

	@include below(320px){
		height:568px;
		margin-bottom:20px;
		padding-top:0;
	}

	video {
		position:absolute;
		top:0;
		left:0;
		height:100%;
		z-index:-1;
		pointer-events:none;
		
		@include above(1440px){
			width:100%;
			height:auto;
		}

		&.mobile {
			display:none;
		}

		@include below(370px){
			&.desktop {
				display:none;
			}

			&.mobile {
				display:block;
			}
		}
	}

	&__wrap {
		max-width:1240px;
		width:100%;
		@include align-both(absolute);
		padding:0 40px;

		@include below(1280px){
			padding:0 20px;
			max-width:100%;
		}

		.button {
			background-color:#F04B3C;
			color:#fff;
			transition:background-color .2s ease-in-out;
			border:none;

			&:hover {
				background-color:#C93F32;
			}
		}
	}

	&__title {
		font-size: rem(100px);
		line-height:rem(78px);
		color:$red;
		margin-bottom:21px;

		@include below(1280px){
			font-size: rem(70px);
			line-height:rem(60px);
		}

		@include below($s){
			font-size:5rem;
			line-height:4.5rem;
		}

		strong {
			font-family:$groteskmedium;
			font-weight: 100;
		}
	}

	p {
		font-size:rem(20px);
		line-height:rem(28px);
		margin-bottom:30px;
		font-family:$groteskregular;

		@include below($s){
			margin-bottom:15px;

			br {
				display:none;
			}
		}
	}
}
.navigation {
	position:fixed;
	top:0;
	left:0;
	right:0;
	z-index:10;

	&__wrap {
		padding:36px 40px;
		position:relative;

		@include below(1280px){
			padding:36px 20px;
		}

		@include below($s){
			padding:20px;
			background-color:#fff;
		}
	}

	&__logotype {
		display:inline-block;

		img {
			vertical-align: top;

			@include below($s){
				max-width:62px;
			}
		}

		@include below($s){
			display:none;
		}

		&--mobile {
			display:none;

			@include below($s){
				display:inline-block;
				opacity:1;
			}
		}
	}

	&__links {
		float:right;
		list-style:none;
		transition:opacity .2s ease-in-out;
		transition-delay:.2s;

		@include below($s){
			display:none !important;
		}

		&.hide {
			opacity:0;
			transition-delay:0;
			pointer-events:none;
		}

		li {
			float:left;
			margin-left:26px;
			padding: 12px 0;
		}

		a {
			text-decoration:none;
			font-size:rem(18px);
			line-height:rem(18px);
			color:rgba(46,46,46, .6);
			position:relative;
			font-family:$groteskregular;
			transition:color .2s ease-in-out;

			&:hover {
				color:rgba(46,46,46, 1);
			}

			&.active {
				color:rgba(46,46,46, 1);
			}
		}

		&--white {
			a {
				color:rgba(255, 255, 255, .6);

				&:hover {
					color:rgba(255, 255, 255, 1);
				}

				&.active {
					color:rgba(255, 255, 255, 1);
				}
			}
		}
	}

	&__hamburger {
		width:32px;
		height:19px;
		display:block;
		position:absolute;
		top:46px;
		right:40px;
		transition:opacity .2s ease-in-out;
		opacity:0;
		pointer-events:none;

		@include below($s){
			top:22px;
			right:20px;
			opacity:1 !important;
			pointer-events:auto !important;
			width:24px;
		}

		&.visible {
			transition-delay:.2s;
			opacity:1;
			pointer-events:auto;
		}

		&--close {
			span {
				position:absolute;
				top:8px;
				background-color:$red !important;
			}
			span:nth-child(1){
				transform:rotate(45deg);
				margin:0;
			}
			span:nth-child(2){
				transform:rotate(-45deg);
				margin:0;
			}
			span:nth-child(3){
				opacity:0;
			}

		}

		span {
			display:block;
			width:100%;
			height:2px;
			background-color:$red;
			margin-bottom:6px;
			transition:transform .2s ease-in-out, opacity .2s ease-in-out, background-color .2s ease-in-out;

			&:last-child {
				margin-bottom:0;
			}

			@include below($s){
				margin-bottom:4px;
			}
		}

		&--white {
			@include above($s){
				span {
					background-color:#fff;
				}
			}
		}
	}
}

.midnight-container {
	z-index:10;

	@include below($s){
		box-shadow: 0 0 10px 0 rgba(0,0,0, 0.07);
	}
}
.job-listing {
	padding:70px 0;
	background-color:#f7f7f7;

	@include below($s){
		padding:20px;
	}

	&__wrapper {
		max-width:1180px;
		width:100%;
		margin: 0 auto;
	}

	&__title {
		font-size:rem(18px);
		line-height:rem(32px);
		font-family:$groteskregular;
		color:rgba(0, 0, 0, .35);
		margin-bottom:40px;
	}
}

.teamtailor-jobs__job {
	padding-bottom:30px;
	margin-bottom:30px;
	border-bottom:1px solid #F0F0F0;
	display:flex;
	flex-direction: row;
}

.teamtailor-jobs__job-title {
	font-size:rem(18px);
	line-height:rem(18px);
	color:#000;
	text-decoration:none;
	font-family:$groteskregular;

	&:hover {
		text-decoration:underline;
	}
}

.teamtailor-jobs__job-info {
	justify-self: flex-end;
	margin-left:auto;
	font-size:rem(18px);
	line-height:rem(18px);
	color:rgba(0, 0, 0, .35);
	font-family:$groteskregular;
}
.sub-menu {
	position:fixed;
	left:0;
	right:0;
	top:110px;
	padding: 34px 40px 26px 40px;
	border-top:1px solid rgba(255, 255, 255, 0.22);
	border-bottom:1px solid rgba(255, 255, 255, 0.22);

	@include below($s){
		padding:20px;
		top:59px;
	}

	ul {
		list-style:none;
	}

	li {
		float:right;
		margin-left:20px;

		@include below($s){
			margin-left:10px;
			float:left;
		}
	}

	a {
		font-size:rem(18px);
		line-height:rem(18px);
		color:rgba(255, 255, 255, .5);
		text-decoration:none;
		font-family:$groteskregular;
		transition:color .2s ease-in-out;

		&.active {
			color:#fff;
		}

		&:hover {
			color:#fff;
		}
	}
}
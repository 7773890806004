.sidebar-navigation {
	height:100vh;
	width:420px;
	background-color:#fff;
	position:fixed;
	top:0;
	right:0;
	z-index:9;
	transform:translateX(100%);
	transition:transform .4s cubic-bezier(.26,.44,.23,.88);
	padding:110px 40px;

	@include below($s){
		padding:0;
		width:100%;
	}

	ul {
		list-style:none;
		@include vertical-align(absolute);
		left:40px;
		right:40px;

		@include below($s){
			width:100%;
			left:0;
			right:0;
		}
	}

	a {
		width:100%;
		display:block;
		font-size: 4rem;
		line-height:4rem;
		text-decoration:none;
		margin-bottom:50px;
		font-family:$groteskregular;
		color:rgba(46, 46, 46, 0.6);
		transition:color .2s ease-in-out;

		@include below($s){
			text-align:center;
		}

		&:hover {
			color:rgba(46, 46, 46, 1);
		}

		&.active {
			color:rgba(46, 46, 46, 1);
		}
	}

	&.visible {
		transform:translateX(0);
		box-shadow: 0 0 50px 0 rgba(0,0,0,0.07);
	}
}
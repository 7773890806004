.article-listing {

	&__intro {
		width:50%;
		margin:0 auto;
		text-align:center;
		margin-top:70px;

		span {
			font-size:rem(18px);
			line-height:rem(32px);
			font-family:$groteskregular;
			color:rgba(46,46,46, .5);
			margin-bottom:33px;
			display:block;
		}

		h2 {
			font-size:rem(72px);
			line-height:rem(64px);
			letter-spacing:-0.72px;
			font-family:$genathregular;

			strong {
				font-family:$groteskregular;
			}
		}

		p {
			font-size:rem(20px);
			line-height:rem(28px);
			color:rgba(46,46,46, .8);
			font-family:$genathregular;
		}

		@include below($s){
			width:100%;
			padding:0 20px;
		}
	}

	&__filter {
		border-bottom:1px solid #E9E5E5;
		clear:both;
		overflow:hidden;
		background-color:#F7F7F7;

		ul {
			list-style:none;
			max-width:1180px;
			width:100%;
			margin:0 auto;
			padding: 35px 0 45px 0;

			@include below(1280px){
				padding: 35px 20px 45px 20px;
				max-width:100%;
			}

			@include below($m){
				padding: 20px 0 25px 20px;
			}

			@include below($s) {
				position: relative;
				overflow: hidden;
				padding-bottom: 10px;
			}
		}

		li {
			float:left;
			margin-right:30px;

			span {
				font-size:rem(18px);
				line-height:rem(18px);
				color:#000;
				font-family:$groteskbold;
				text-decoration:none;
				transition:color .2s ease-in-out;
				letter-spacing:rem(0.20px);

				@include above($m){
					z-index:12;
					position:relative;
				}
			}

			@include below($s) {
				margin-bottom: 10px;
			}
		}

		a {
			font-size:rem(18px);
			line-height:rem(18px);
			color: #8a8a8a;
			text-decoration:none;
			font-family:$groteskregular;
			transition:color .2s ease-in-out;

			@include above($m){
				z-index:12;
				position:relative;
			}

			&.no-chevron {
				&:after {
					display:none;
				}
			}

			&:after {
				border-style: solid;
				border-width: 1px 1px 0 0;
				content: '';
				display: inline-block;
				height: rem(5px);
				left: rem(5px);
				position: relative;
				top: rem(2px);
				transform: rotate(135deg);
				vertical-align: top;
				width: rem(5px);
			}

			&.active {
				color:#000000;

				&:after {
					top:rem(6px);
					transform: rotate(-45deg);
				}
			}

			&:hover {
				color:#000000;
			}
		}
	}

	&__sub-filter {
		opacity:0;
		max-height:0;
		overflow:hidden;
		transition:max-height .6s ease-in-out, opacity .6s ease-in-out;

		&.visible {
			opacity:1;
			max-height:2000px;
			overflow:visible;
		}

		.filterwrap {
			border-bottom:1px solid #E9E5E5;
		}

		ul {
			max-width:1180px;
			width:100%;
			margin:0 auto;
			padding: 40px 0 30px 0;
			display:flex;
			flex-direction: row;
			flex-wrap:wrap;
			list-style:none;

			@include below($m){
				padding: 20px 0 10px 0;
			}
		}

		li {
			width:25%;
			padding-right:20px;
			margin-bottom:10px;

			@include below(1180px){
				padding:0 20px;
			}

			@include below($m){
				width:50%;
				padding:0 20px;
			}

			@include below($s){
				width:100%;
			}
		}

		a {
			font-size:rem(18px);
			line-height:rem(18px);
			color:rgba(0, 0, 0, 0.6);
			font-family:$groteskregular;
			text-decoration:none;
			transition:color .2s ease-in-out;
			position:relative;
			z-index:12;

			&.active {
				color:#000;
			}

			&:hover {
				color:#000;
			}
		}
	}


	&__grid {
		max-width:1180px;
		width:100%;
		margin:0 auto;
		display:grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 40px;
		grid-row-gap:40px;
		padding:70px 0;

		@include below(1280px){
			max-width:100%;
			padding: 20px;
		}

		@include below($s){
			display:block;
			padding:20px;
		}
	}

	&__grid-item {
		opacity:0;
		transform:scale(1.1);
		transition:transform .2s ease-in-out;
		min-height:600px;

		&--full-width {
			grid-column: span 2;
		}

		@include below($s){
			width:100%;
			margin-bottom:20px;
			min-height:auto;
		}
	}

	&__grid-page-break{
		display: none;
	}

	&__grid-load-more{
		grid-column: span 2;
		text-align: center;
	}
}
@import 'normalize';
@import 'entypo';
@import 'breaker';
@import 'mixins';
@import 'fonts.scss';
@import 'swiper';

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 500px;
$m: 800px;
$l: 1050px;
$xl: 1410px;
$xxl: 1800px;

$red: #F04B3C;
$yellow: #FFAF37;
$blue: #2D4691;
$green: #055F55;
$purple: #4B2D5F;

$rem-baseline: 10px;

$grid-item-padding: 60px;
$grid-item-padding-small: 30px;

html {
	@include rem-baseline;


	@media (max-width: 1280px) {
		@include rem-baseline(80%);

	}

	@media (max-width: 800px) {
		@include rem-baseline(65%);
	}

	@media (max-width: 500px) {
		@include rem-baseline(75%);
		text-size-adjust: none;
	}
}

.big-speak__wrap,
.team,
.footer__wrap {
	max-width: 1350px !important;
}

body {
	background-color:#f0efef;
	color:#2E2E2E;
	font-family:$genathregular;

	&.logged-in {
		.midnight-container {
			top:32px !important;
		}
	}

	@include below($s){
		padding-top:59px;
	}
}

.video-hero video.mobile {
	@include below($s){
		width: 100% !important;
		height: auto !important;
		display: block !important
	}
}

.video-hero__wrap {
	max-width: 1350px !important;
	padding: 0;
	@include above($xl){
		padding: 0 40px;
	}
}

.big-speak__holder {
	@include below($xl){
		padding: 0 20px;
	}
}

.team {
	.team__intro {
		width: 100%;
		text-align: left;
		@include below($s){
			margin: 40px auto;
		}
		h2 {
			font-size: 4.2rem;
			font-weight: 500;
			font-family: $groteskmedium;
			margin-bottom: 20px;
		}
		p {
			font-size: 2.5rem;
			font-family: $groteskregular;
		}
	}
	.team__grid {
		@include below($xl){
			padding: 0 10px;
		}
	}
	@include below($s) {
		.team__column {
			padding: 0 20px;
		}
	}
}

.contact-block {
	.contact-block__inner {
		max-width: 1350px !important;
		@include below($xl) {
			padding: 0 20px;
		}
	}

	&.jpg-background {
		background-size: cover !important;
	}
}

// Centering the acf content
.grid-masonry,
.grid-full-width,
.grid-masonry.grid-masonry--reversed,
.grid-full-width {
	max-width:1350px;
	width:100%;
	margin:40px auto;

	@include below(1220px){
		padding:0 20px;
		max-width:100%;
	}
}

.grid-overflow-top {
	margin-top:-100px;

	@include below(1220px){
		margin-top:-50px;
	}
	@include below($s){
		margin-top:-40px;
		z-index:7;
		position:relative;
	}
}

.grid-overflow-bottom {
	margin-bottom:-100px;

	@include below($s){
		margin-bottom:-40px;
	}
}

.grid-2 {
	display:grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 20px;
	grid-row-gap:20px;
	margin-bottom:20px;

	@include below($s){
		grid-template-columns:repeat(1, 1fr);
	}
}

.grid-masonry {
	@include above($m){
		display:grid;
		grid-template-areas:
				"b a a"
				"b a a"
				"b c c";
		grid-template-columns: 40.677% auto auto;
		grid-auto-flow: dense;
		align-items:end;
		grid-row-gap: 20px;
		grid-column-gap: 20px;
		margin-bottom:20px;
	}

	@include below($m){
		.grid-item {
			margin-bottom:20px;
		}
	}

	@include above($m){
		.grid-item:nth-child(1){
			grid-area:b;
			min-height:650px;

			.news-card__inner, .article-card__inner, .speak-card__inner {
				@include align-both(absolute);
				width:100%;
				padding: $grid-item-padding;

				@include below($l){
					padding:$grid-item-padding-small;
				}
			}
		}

		.grid-item:nth-child(2){
			grid-area:a;
			min-height:500px;

			.news-card__inner, .article-card__inner, .speak-card__inner {
				@include align-both(absolute);
				width:100%;
				padding: $grid-item-padding;

				@include below($l){
					padding:$grid-item-padding-small;
				}
			}
		}

		.grid-item:nth-child(3){
			grid-area:c;

			.news-card__inner, .article-card__inner, .speak-card__inner {
				position:static;
				transform:none;
				width:100%;
				padding: $grid-item-padding;


				@include below($l){
					padding:$grid-item-padding-small;
				}
			}
		}
	}

	&--reversed {
		@include above($m){
			grid-template-areas:
			"a a b"
			"c c b"
			"c c b";
			grid-template-columns: auto auto 40.677%;
			align-items:start;
		}

		@include above($m){
			.grid-item:nth-child(1){
				grid-area:b;
				min-height:650px;

				.news-card__inner, .article-card__inner, .speak-card__inner {
					@include align-both(absolute);
					width:100%;
					padding:$grid-item-padding;

					@include below($l){
						padding:$grid-item-padding-small;
					}
				}
			}
		}

		@include above($m){
			.grid-item:nth-child(2){
				grid-area:a;
				min-height:auto;
				.news-card__inner, .article-card__inner, .speak-card__inner {
					position:static;
					transform:none;
					width:100%;
					padding:$grid-item-padding;


					@include below($l){
						padding:$grid-item-padding-small;
					}
				}
			}
		}

		@include above($m){
			.grid-item:nth-child(3){
				grid-area:c;
				min-height:500px;

				.news-card__inner, .article-card__inner, .speak-card__inner {
					@include align-both(absolute);
					width:100%;
					padding:$grid-item-padding;

					@include below($l){
						padding:$grid-item-padding-small;
					}
				}
			}
		}
	}
}


body {
	&.home,
	&.page-template-frontpage {
		.collapsible-boxes-container .grid-item {
			@include above($xl){
				&:hover,
				&:focus {
					background: $red;
					transition: background 0.2s ease;
				}
			}
			@include below($xl){
				background: $red;
			}
		}
	}
	&.page-template-page-symbios {
		.collapsible-boxes-container .grid-item {
			@include above($xl){
				&:hover,
				&:focus {
					background: $purple;
					transition: background 0.2s ease;
				}
			}
			@include below($xl){
				background: $purple;
			}
		}
	}
	&.page-template-page-work-with-us {
		.collapsible-boxes-container .grid-item {
			@include above($xl){
				&:hover,
				&:focus {
					background: $blue;
					transition: background 0.2s ease;
				}
			}
			@include below($xl){
				background: $blue;
			}
		}

	}
	&.page-template-page-development {
		.collapsible-boxes-container .grid-item {
			@include above($xl){
				&:hover,
				&:focus {
					background: $yellow;
					transition: background 0.2s ease;
				}
			}
			@include below($xl){
				background: $yellow;
			}
		}
	}
	&.page-template-page-who-we-are {
		.collapsible-boxes-container .grid-item {
			@include above($xl){
				&:hover,
				&:focus {
					background: $green;
					transition: background 0.2s ease;
				}
			}
			@include below($xl){
				background: $green;
			}
		}
	}
 }


.collapsible-boxes-container {
	@include above($l) {
		margin: 100px 40px 40px !important;
	}

	@include below($l) {
		margin: 60px 20px 20px;
	}

	.collapsible__title,
	.collapsible__text{
		max-width: 1350px;
		margin-left: auto;
		margin-right: auto;
	}

	.collapsible__title {
		font-size: 4.2rem;
		font-weight: 500;
		font-family: $groteskmedium;
	}
	
	.collapsible__title:empty,
	.collapsible__text:empty {
		display: none;
	}

	.collapsible__text {
		font-size: 2.5rem;
		margin-bottom: 5rem;
		font-family: $groteskregular;
	}

	.collapsible-boxes {
		gap: 20px;
		margin-bottom: 40px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.article-card__title {
			font-weight: 500;
			font-family: $genathregular;
			margin-bottom: 24px;
		}

		@include above($xxl) {
			.article-card__title {
				font-size: 4rem;
				line-height: 4rem;
			}
		}

		@include below($xxl) {
			.article-card__title {
				font-size: 3.6rem;
				line-height: 3.5rem;
			}
		}

		@include above($xl) {
			.grid-item {
				flex: 0 0 calc(25% - 20px);
			}
		}

		@include below($xl) {
			.grid-item {
				flex: 0 0 100%;
			}
		}

		@include below($m) {
			margin-bottom: 60px;
		}

		.grid-item {
			background: #a9a9a9;

			.article-card__continue span {
				text-decoration: none;
			}
			.article-card__read-more {
				text-decoration: none;
				display: flex;
				color: white;

				span {
					align-self: center;
					margin-top: 4px;
					margin-left: 10px;
					font-size: 1.5rem;
					line-height: 1.5rem;
					font-family: $groteskregular;
					-webkit-transition: color .2s ease-in-out;
					-o-transition: color .2s ease-in-out;
					transition: color .2s ease-in-out;
				}

				&:hover,
				&:focus {
					cursor: pointer;
					span {
						text-decoration: underline;
					}
				}
			}

			&::after {
				content: none;
			}

			.article-card__inner {
				transform: none;
				top: 0;
				padding: 30px;
				text-align: left;
				@include below($xl){
					padding: 20px;
				}

				.article-card__content {
					&.limited-text {
						display: -webkit-box;
						-webkit-line-clamp: 11;
						-webkit-box-orient: vertical;  
						overflow: hidden;
					}
					p {
						font-family: $groteskregular;
					}
					a {
						color: #fff;
					}
				}
			}
			.article-card__continue,
			.article-card__read-more {
				width: -moz-fit-content;
				width: fit-content;
				align-items: center;

				position: absolute !important;
				bottom: 2em;
				left: 2em;

				img {
					width: 4em;
					height: 4em;
				}

				&:hover,
				&:focus {
					cursor: pointer;
					span {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.masonry-centered {
	max-width: 1350px;
	font-family: $groteskregular;
	
	.masonry__title {
		font-size: 4.2rem;
		font-weight: 500;
		font-family: $groteskmedium;
		margin-bottom: 2rem;
	}

	.masonry__text {
		font-size: 2.5rem;
		font-family: $groteskregular;
	}

	@include above($l){
		margin: 100px auto 40px;
	}
	@include below($xl){
		margin: 40px auto 0;
		padding: 0 20px;
	}

	p {
		font-size: 2.5rem;

		& > img {
			padding: 40px 0;
		}
	}

	img.full-width-video {
		width: 100%;
		padding: 40px 0;
	}

	.img_gallery_grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;
		padding-top: 80px;
		width: 60%;
		margin: 0 auto;
		align-items: center;

		img {
			width: 100%;
		}

		@include below($m){
			padding-top: 40px;
		}
		@include below($s){
			width: 80%
		}
	}
	&.about-cupole {
		max-width: none;
		margin: 100px 0 40px;

		> :not(:has(video)) {
			margin-left: auto;
			margin-right: auto;
			max-width: 1350px;
		}
	}
	.grid-full-width {
		padding: 0 !important;
	}
}

.grid-full-width {
	&:has(> .grid-video > video) {
		margin: 0 !important;
		min-width: 100%;
		padding: 0 40px;
		background: #f0efef;
	}

	&:not(:has(> .grid-video > video)) {
		padding: 0 20px;
	}
}

.grid-overflow-hidden {
	overflow:hidden;
}

.overlay {
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color:rgba(0, 0, 0, .7);
	z-index:98;
	opacity:0;
	pointer-events:none;
	transition:opacity .4s ease-in-out;

	&.visible {
		opacity:1;
		pointer-events:auto;
	}
}
.grid-video{
	img{
		border: 0 !important;
		width: 100% !important;
	}
}

.slider {
	.slider__slide__inner {
		max-width: 1350px !important;
		@include above($s) {
			.slider__slide__content {
				padding: 0 20px;
			}
		}
	}
}

.job-listing {
	@include above($l){
		padding: 70px 40px !important;
	}
	@include below($l){
		padding: 40px 20px !important;
	}
	.team.job-listing-intro,
	.job-listing__wrapper {
		max-width: 1350px;
		.team__intro {
			margin: 0 0 80px;
			text-align: left;

			@include below($s){
				padding: 0;
			}
			
			h2 {
				font-size: 4.2rem;
				margin-bottom: 4rem;
				font-family: $groteskmedium;
			}
		}
	}
}

.footer {
	.footer__wrap {
		@include below($xl){
			padding: 0 20px;
		}
	}
}

#CybotCookiebotDialog {
	width: 80vw !important;
	min-width: 80vw !important;
}
#CybotCookiebotDialog *{
	font-size: 12px !important;
}
#CybotCookiebotDialogHeader{
	display: none !important;
}
@import 'components/navigation';
@import 'components/sub-menu';
@import 'components/sidebar-navigation';
@import 'components/video-hero';
@import 'components/button';
@import 'components/speak-card';
@import 'components/article-card';
@import 'components/link-card';
@import 'components/job-listing';
@import 'components/slider';
@import 'components/meet-user';
@import 'components/intervju-slider';
@import 'components/news-card';
@import 'components/contact-block';
@import 'components/big-speak';
@import 'components/development-header';
@import 'components/article-listing';
@import 'components/link-listing';
@import 'components/team';
@import 'components/thoughtpiece-card';
@import 'components/breadcrumbs';
@import 'components/post-navigation';
@import 'components/article';
@import 'components/footer';
@import 'components/chatbot';
@import 'components/list';
@import 'components/default-template';
.masonry-centered-text {
    max-width: 900px;
    text-align: center;
    color: $red;

    @include above($l) {
        margin: 120px auto 80px;
    }

    @include below($l) {
        margin: 60px auto;
    }

    @include below($l) {
        padding: 0 20px;
    }

    .masonry__title {
        font-size: 5rem;
        margin-bottom: 2rem;

        @include below($m) {
            font-size: 4rem;
        }
    }

    .centered-text__user_link {
        margin-top: 1rem;
        color: $red;
        border-color: $red;

        &.team__member:after {
            background-image: none !important;
        }
    }
}
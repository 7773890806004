.breadcrumbs {
	margin-top:110px;
	border-top:1px solid #EDEDED;
	border-bottom:1px solid #EDEDED;
	padding:18px 0;

	@include below(1180px){
		padding:18px 20px;
	}

	@include below($s){
		margin-top:20px;
		margin-bottom:20px;
		padding:10px 20px;
	}

	&__inner {
		max-width:1180px;
		width:100%;
		margin:0 auto;
		overflow:hidden;
		clear:both;
	}

	&__back {
		display:flex;
		align-self: flex-start;
		float:left;
		text-decoration:none;
		color:inherit;

		&:hover {
			text-decoration:underline;
		}

		span {
			display:inline-block;
			color:#000;
			font-size:rem(12px);
			line-height:rem(12px);
			font-family:$groteskregular;
			letter-spacing:0.4px;
			align-self:center;
			justify-self: center;
			margin-top:4px;
		}

		img {
			width:40px;
			height:40px;
			margin-right:18px;
		}
	}

	&__current {
		max-width:680px;
		width:100%;
		margin:0 auto;
		padding-top:13px;

		span {
			font-family:$groteskregular;
			font-size:rem(18px);
			line-height:rem(18px);
			color:rgba(46, 46, 46, 0.5);
			display:block;
		}

		@include below(1180px){
			float:right;
			text-align:right;
		}

		@include below($m){
			width:50%;
			padding-top: 16px;
		}

		@include below($s){
			display:none;
		}
	}
}
.intervju {
	
	.intervju_slideshow {
		position: relative;
		min-height: 350px;
		max-width: 900px;
		text-align: center;
		color: $red;

		@include above($l) {
			margin: 120px auto 80px;
		}

		@include below($l) {
			margin: 60px auto;
		}

		@include below($l) {
			padding: 0 20px;
		}

		.intervju-slider {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.6s ease, visibility 0.6s ease;

			.intervju__title {
				font-size: 5rem;
				margin-bottom: 2rem;

				@include below($m) {
					font-size: 4rem;
				}
			}

			.centered-text__intervju {
				margin-top: 1rem;
				color: $red;
				border-color: $red;

				&.team__member:after {
					background-image: none !important;
				}
			}

			&.visible {
				opacity: 1;
				visibility: visible;

				.slider__slide__content {
					transform: translateX(0);
					display: block;
					transition-delay: .6s;
				}
			}
		}

		&__controls {
			display: flex;
			flex-direction: row;
			position: absolute;
			bottom: -40px;
			flex-wrap: wrap;
			width: 100%;
			justify-content: center;
			align-content: center;

			p {
				align-self: center;
				font-size: 12px;
				color: $red;
				font-family: $groteskregular;
				margin: 0 20px;
			}

			.control {
				width: 40px;
				height: 40px;
				background-size: 40px 40px;
				border-color: #000;
			}

			.next {
				background-image: url('../img/icon-intervju-next.svg');
			}

			.prev {
				background-image: url('../img/icon-intervju-prev.svg');
			}
		}
	}
}
@font-face {
	font-family: 'Conv_Founders Grotesk - Medium';
	src: url('../fonts/Founders Grotesk - Medium.eot');
	src: local('☺'), url('../fonts/Founders Grotesk - Medium.woff') format('woff'), url('../fonts/Founders Grotesk - Medium.ttf') format('truetype'), url('../fonts/Founders Grotesk - Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

$groteskmedium: 'Conv_Founders Grotesk - Medium';

@font-face {
	font-family: 'Conv_Founders Grotesk - Bold';
	src: url('../fonts/Founders Grotesk - Bold.eot');
	src: local('☺'), url('../fonts/Founders Grotesk - Bold.woff') format('woff'), url('../fonts/Founders Grotesk - Bold.ttf') format('truetype'), url('../fonts/Founders Grotesk - Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

$groteskbold: 'Conv_Founders Grotesk - Bold';

@font-face {
	font-family: 'Conv_Founders Grotesk - Regular';
	src: url('../fonts/Founders Grotesk - Regular.eot');
	src: local('☺'), url('../fonts/Founders Grotesk - Regular.woff') format('woff'), url('../fonts/Founders Grotesk - Regular.ttf') format('truetype'), url('../fonts/Founders Grotesk - Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

$groteskregular: 'Conv_Founders Grotesk - Regular';

@font-face {
	font-family: 'Conv_Genath-Regular';
	src: url('../fonts/Genath-Regular.eot');
	src: local('☺'), url('../fonts/Genath-Regular.woff') format('woff'), url('../fonts/Genath-Regular.ttf') format('truetype'), url('../fonts/Genath-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

$genathregular: 'Conv_Genath-Regular';

@font-face {
	font-family: 'Conv_Genath-Italic';
	src: url('../fonts/Genath-Italic.eot');
	src: local('☺'), url('../fonts/Genath-Italic.woff') format('woff'), url('../fonts/Genath-Italic.ttf') format('truetype'), url('../fonts/Genath-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

$genathitalic: 'Conv_Genath-Italic';


$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
  @if $separator == "comma" or $separator == "space" {
    @return append($list, null, $separator);
  }

  @if function-exists("list-separator") == true {
    @return list-separator($list);
  }

  // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);

  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
      $result: append($result, $value / $rem-baseline * 1rem, $separator);
    } @else if type-of($value) == "list" {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == "map" {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}